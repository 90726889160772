import React from "react"

import {
  Container,
  Theme,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import Image, { StaticImageData } from "next/image"
import Academy from "public/assets/landing/learning/schools/academy.webp"
import AvalonHeights from "public/assets/landing/learning/schools/avalonHeights.webp"
import BloomingDale from "public/assets/landing/learning/schools/bloomingDale.webp"
import CapitalSchool from "public/assets/landing/learning/schools/capitalSchool.webp"
import CpGoenka from "public/assets/landing/learning/schools/cpGoenka.webp"
import DubaiInternational from "public/assets/landing/learning/schools/dubaiInternational.webp"
import GemsRoyal from "public/assets/landing/learning/schools/gemsRoyal.webp"
import GoodShephard from "public/assets/landing/learning/schools/goodShephard.webp"
import Harvest from "public/assets/landing/learning/schools/harvest.webp"
import Kothari from "public/assets/landing/learning/schools/kothari.webp"
import Lycee from "public/assets/landing/learning/schools/lycee.webp"
import MilleniumSchool from "public/assets/landing/learning/schools/milleniumSchool.webp"
import Nalapad from "public/assets/landing/learning/schools/nalapad.webp"
import Oakridge from "public/assets/landing/learning/schools/oakridge.webp"
import Ryan from "public/assets/landing/learning/schools/ryan.webp"
import Sais from "public/assets/landing/learning/schools/sais.webp"
import Ssvm from "public/assets/landing/learning/schools/ssvm.webp"
import SwissInternational from "public/assets/landing/learning/schools/swissInternational.webp"
import TheCitySchool from "public/assets/landing/learning/schools/theCitySchool.webp"
import TheDoon from "public/assets/landing/learning/schools/theDoon.webp"
import TheInternationalSchool from "public/assets/landing/learning/schools/theInternationalSchool.webp"
import { createUseStyles, useTheme } from "react-jss"

const useStyles = createUseStyles(theme => ({
  root: {
    overflow: "hidden",
    background: "white",
  },

  heading: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },

  "@keyframes marquee": {
    from: {
      transform: "translate3d(0% , 0, 0)",
    },

    to: {
      transform: "translate3d(-50%, 0, 0)",
    },
  },

  sliderContainer: {
    position: "relative",
    height: "150px",
  },

  slider: {
    position: "absolute",
    top: 0,
    left: 0,
    overflow: "hidden",

    "& .slider-inner": {
      width: "max-content",
      display: "flex",
      position: "relative",
      animation: "$marquee 50s linear infinite",
    },
  },

  img: {
    margin: theme.spacing(0, 2),
    position: "relative",
    width: "200px",
    height: "150px",
  },
}))

export type SchoolImagesType = { name: string; img: StaticImageData }[]

const uaeSchools: SchoolImagesType = [
  { name: "Academy,", img: Academy },
  { name: "The doon,", img: TheDoon },
  { name: "Lycee,", img: Lycee },
  { name: "The city school,", img: TheCitySchool },
  { name: "Ssvm,", img: Ssvm },
  { name: "Swiss international,", img: SwissInternational },
  { name: "The international school,", img: TheInternationalSchool },
  { name: "Capital school,", img: CapitalSchool },
  { name: "Millenium school,", img: MilleniumSchool },
  { name: "Dubai international,", img: DubaiInternational },
  { name: "Oakridge,", img: Oakridge },
  { name: "Good shephard,", img: GoodShephard },
  { name: "Gems royal,", img: GemsRoyal },
  { name: "Sais,", img: Sais },
]

const globalSchools: SchoolImagesType = [
  { name: "The doon", img: TheDoon },
  { name: "Avalon heights", img: AvalonHeights },
  { name: "Ssvm", img: Ssvm },
  { name: "Kothari", img: Kothari },
  { name: "Swiss international", img: SwissInternational },
  { name: "Blooming dale", img: BloomingDale },
  { name: "The international school", img: TheInternationalSchool },
  { name: "Harvest", img: Harvest },
  { name: "Capital school", img: CapitalSchool },
  { name: "Cp goenka", img: CpGoenka },
  { name: "Nalapad", img: Nalapad },
  { name: "Dubai international", img: DubaiInternational },
  { name: "Oakridge", img: Oakridge },
  { name: "Good shephard", img: GoodShephard },
  { name: "Ryan", img: Ryan },
]
export default function SchoolPartners({
  country,
  className,
  customTitle,
  hiddenOnMobile = false,
  customImages,
  height,
}: {
  country: string | null
  customTitle?: string | null
  className?: string
  hiddenOnMobile?: boolean
  customImages?: SchoolImagesType
  height?: number
}) {
  const classes = useStyles()
  const theme = useTheme<Theme>()
  const isXs = useMediaQuery(theme.breakpoints.down("xs"))

  const getImages = () => {
    if (customImages) {
      return customImages
    }
    return country === "AE" ? uaeSchools : globalSchools
  }

  const images = getImages()

  const generateTitle = () => {
    if (customTitle) {
      return customTitle
    }
    if (country === "AE") {
      return "195+ International Schools Hire from Suraasa"
    }
    return "100s of Premium Schools Who Hire Suraasa Teachers"
  }

  if (hiddenOnMobile && isXs) return null

  return (
    <div className={clsx(classes.root, "py-6 md:py-10", className)}>
      <Container>
        <div className="flex items-center justify-center mt-2 mb-6 sm-mb-7">
          <Typography
            className={clsx(classes.heading, "mb-1.75")}
            variant="title2"
          >
            {generateTitle()}
            {/* {country === "AE"
              ? "195+ International Schools Hire from Suraasa"
              : "100s of Premium Schools Who Hire Suraasa Teachers"} */}
          </Typography>
        </div>
      </Container>

      <div className={classes.sliderContainer}>
        <div className={clsx(classes.slider)}>
          <div className="slider-inner">
            {images.map(({ name, img }, i) => (
              <div className={classes.img} key={i} style={{ height }}>
                <Image
                  alt={name}
                  className="object-contain"
                  src={img}
                  fill
                  priority
                />
              </div>
            ))}
            {images.map(({ name, img }, i) => (
              <div className={classes.img} key={i} style={{ height }}>
                <Image
                  alt={name}
                  className="object-contain"
                  src={img}
                  fill
                  priority
                />
              </div>
            ))}
            {images.length < 12 &&
              images.map(({ name, img }, i) => (
                <div className={classes.img} key={i} style={{ height }}>
                  <Image
                    alt={name}
                    className="object-contain"
                    src={img}
                    fill
                    priority
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}
