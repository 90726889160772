import React, { useContext } from "react"

import {
  Container,
  Theme,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import Image from "next/image"
import ClickMeDown from "public/assets/olympiad/contact-us/click-me-down.svg"
import ClickMeUp from "public/assets/olympiad/contact-us/click-me-up.svg"
import Email from "public/assets/olympiad/contact-us/email.svg"
import Phone from "public/assets/olympiad/contact-us/phone.svg"
import Whatsapp from "public/assets/olympiad/contact-us/whatsapp.svg"
import { createUseStyles, useTheme } from "react-jss"

import { GlobalContext } from "components/GlobalContext"
import { itoSections } from "utils/constants"
import { TRACKING_DATA } from "utils/googleAnalytics"

const useStyles = createUseStyles(theme => ({
  title: {
    fontFamily: "Reckless TRIAL",
    fontWeight: "700",
    fontSize: "48px",
    lineHeight: "58px",

    [theme.breakpoints.down("sm")]: {
      fontSize: "34px",
      lineHeight: "41px",
    },
  },

  cardRoot: {
    cursor: "pointer",
    background: "white",
    borderRadius: theme.spacing(1),
    boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.05)",
    width: "100%",
    textDecoration: "none",
    color: "#050124",
  },
  cardBody: {
    wordBreak: "break-word",
  },
  highlight: {
    position: "relative",
    border: "2px solid #69DB7B",
    backgroundColor: "#E4F9EB",
  },
  clickMeContainer: {
    position: "absolute",
    right: 26,
    top: 88,
    [theme.breakpoints.down("sm")]: {
      right: 13,
      top: -49,
    },
  },
}))

export const countries = {
  IN: "919986702707",
  AE: "971585483678",
  OM: "971585483678",
  BH: "971585483678",
  SA: "971585483678",
}

type CardProps = {
  title: string
  body: string
  href: string
  highlight?: boolean
  icon: any
}

const Card = ({ title, body, icon, highlight = false, href }: CardProps) => {
  const classes = useStyles()
  const theme = useTheme<Theme>()
  const isSm = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <a
      className={clsx("p-2 flex gap-2", classes.cardRoot, {
        [classes.highlight]: highlight,
      })}
      data-feature={TRACKING_DATA.feature.ito}
      data-medium={title.toLowerCase()}
      data-purpose={TRACKING_DATA.purpose.contactUs}
      data-source={TRACKING_DATA.source.itoLandingPage}
      data-trackingid="ito-contact-us"
      href={href}
    >
      <div>
        <Image alt="icon" src={icon} />
      </div>
      <div>
        <Typography color="onSurface.500" variant="smallBody">
          {title}
        </Typography>
        <Typography className={classes.cardBody} variant="strongSmallBody">
          {body}
        </Typography>
      </div>
      {highlight && (
        <div className={classes.clickMeContainer}>
          <Image alt="click me" src={isSm ? ClickMeDown : ClickMeUp} />
        </div>
      )}
    </a>
  )
}

const ContactUs = ({
  hideWhatsapp = false,
  hidePhoneNo = false,
}: {
  hideWhatsapp?: boolean
  hidePhoneNo?: boolean
}) => {
  const classes = useStyles()
  const { currentCountry } = useContext(GlobalContext)

  const getPhoneNumber = () => {
    if (!currentCountry) return countries.IN

    const phoneNumber =
      countries[currentCountry.isoCode as keyof typeof countries]

    if (!phoneNumber) {
      return countries.IN
    }
    return phoneNumber
  }

  return (
    <div className="pb-7" id={itoSections.CONTACT}>
      <Container>
        <span className={classes.title}>Contact Us</span>
        <Typography className="mt-5" variant="title3">
          Need help? We’re here for you.
        </Typography>
        <Typography className="mt-2" variant="body">
          (Working Hours: Monday to Sunday. 9 AM to 6 PM IST)
        </Typography>

        <div className="flex flex-col gap-2 mt-8 sm:mt-4 md:flex-row">
          {!hideWhatsapp && (
            <Card
              body={`+${getPhoneNumber()}`}
              href={`https://wa.me/${getPhoneNumber()}`}
              icon={Whatsapp}
              title="WhatsApp"
              highlight
            />
          )}
          {!hidePhoneNo && (
            <Card
              body={`+${getPhoneNumber()}`}
              href={`tel:+${getPhoneNumber()}`}
              icon={Phone}
              title="Phone"
            />
          )}
          <Card
            body="care@teachersolympiad.com"
            href="mailto:care@teachersolympiad.com"
            icon={Email}
            title="Email"
          />
        </div>
      </Container>
    </div>
  )
}

export default ContactUs
