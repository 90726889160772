import React from "react"

import {
  Button,
  Container,
  Theme,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import Image from "next/image"
import CurlyPath from "public/assets/olympiad/hero-section/curl-path.svg"
import LeftQuoteMark from "public/assets/olympiad/hero-section/left-quote-mark.svg"
import PeopleImage from "public/assets/olympiad/hero-section/people-image.webp"
import PeopleImageMobile from "public/assets/olympiad/hero-section/people-image-mobile.webp"
import RightQuoteMark from "public/assets/olympiad/hero-section/right-quote-mark.svg"
// indian school partners
import TheHDFCSchool from "public/assets/olympiad/school-partner/indian-schools/in-image-1.webp"
import ArmyPublicSchool from "public/assets/olympiad/school-partner/indian-schools/in-image-2.webp"
import CalcuttaInternationalSchool from "public/assets/olympiad/school-partner/indian-schools/in-image-3.webp"
import BombayCambridgeInternationalSchool from "public/assets/olympiad/school-partner/indian-schools/in-image-4.webp"
import DelhiPublicSchool from "public/assets/olympiad/school-partner/indian-schools/in-image-5.webp"
import HeritageInternationalXperientialSchool from "public/assets/olympiad/school-partner/indian-schools/in-image-6.webp"
import LegacySchool from "public/assets/olympiad/school-partner/indian-schools/in-image-7.webp"
import NeerjaModiSchool from "public/assets/olympiad/school-partner/indian-schools/in-image-8.webp"
import ChettinadVidyaMandir from "public/assets/olympiad/school-partner/indian-schools/in-image-9.webp"
import DhirubhaiAmbaniInternationalSchool from "public/assets/olympiad/school-partner/indian-schools/in-image-10.webp"
import GemsInternalSchool from "public/assets/olympiad/school-partner/indian-schools/in-image-11.webp"
import OakridgeInternationalSchool from "public/assets/olympiad/school-partner/indian-schools/in-image-12.webp"
import ShivNadarSchool from "public/assets/olympiad/school-partner/indian-schools/in-image-13.webp"
import AhlconInternationalSchool from "public/assets/olympiad/school-partner/indian-schools/in-image-14.webp"
import AdaniWorldSchool from "public/assets/olympiad/school-partner/indian-schools/in-image-15.webp"
// uae school partners
import InternationalIndianSchool from "public/assets/olympiad/school-partner/uae-schools/uae-image-1.webp"
import AlSalamPrivateSchool from "public/assets/olympiad/school-partner/uae-schools/uae-image-2.webp"
import CredenceHighSchool from "public/assets/olympiad/school-partner/uae-schools/uae-image-3.webp"
import DeiraInternationalSchool from "public/assets/olympiad/school-partner/uae-schools/uae-image-4.webp"
import DubaiBritishSchool from "public/assets/olympiad/school-partner/uae-schools/uae-image-5.webp"
import JumeirahEnglishSpeakingSchool from "public/assets/olympiad/school-partner/uae-schools/uae-image-6.webp"
import ShiningStarInternationalSchool from "public/assets/olympiad/school-partner/uae-schools/uae-image-7.webp"
import TheIndianHighSchoolDubai from "public/assets/olympiad/school-partner/uae-schools/uae-image-8.webp"
import LeadKindlyLight from "public/assets/olympiad/school-partner/uae-schools/uae-image-9.webp"
import { createUseStyles, useTheme } from "react-jss"

import SchoolPartners, { SchoolImagesType } from "components/SchoolPartners"
import customTheme from "utils/customTheme"
import { getPlatformURL } from "utils/helpers"

export const globalSchools: SchoolImagesType = [
  { name: "the-hdfc-school,", img: TheHDFCSchool },
  { name: "army-public-school,", img: ArmyPublicSchool },
  { name: "calcutta-international-school,", img: CalcuttaInternationalSchool },
  {
    name: "bombay-cambridge-international-school",
    img: BombayCambridgeInternationalSchool,
  },
  { name: "delhi-public-school", img: DelhiPublicSchool },
  {
    name: "heritage-international-xperiential-school",
    img: HeritageInternationalXperientialSchool,
  },
  { name: "legacy-school", img: LegacySchool },
  { name: "neerja-modi-school", img: NeerjaModiSchool },
  { name: "chettinad-vidya-mandir", img: ChettinadVidyaMandir },
  {
    name: "dhirubhai-ambani-international-school",
    img: DhirubhaiAmbaniInternationalSchool,
  },
  { name: "gems-internal-school", img: GemsInternalSchool },
  { name: "oakridge-international-school", img: OakridgeInternationalSchool },
  { name: "shiv-nadar-school", img: ShivNadarSchool },
  { name: "ahlcon-international-school", img: AhlconInternationalSchool },
  { name: "adani-world-school", img: AdaniWorldSchool },
]

export const uaeSchools: SchoolImagesType = [
  { name: "international-indian-school", img: InternationalIndianSchool },
  { name: "al-salam-private-school", img: AlSalamPrivateSchool },
  { name: "credence-high-school", img: CredenceHighSchool },
  { name: "deira-international-school", img: DeiraInternationalSchool },
  { name: "dubai-british-school", img: DubaiBritishSchool },
  {
    name: "jumeirah-english-speaking-school",
    img: JumeirahEnglishSpeakingSchool,
  },
  {
    name: "shining-star-international-school",
    img: ShiningStarInternationalSchool,
  },
  { name: "the-indian-high-school-dubai", img: TheIndianHighSchoolDubai },
  { name: "lead-kindly-light", img: LeadKindlyLight },
]

const useStyles = createUseStyles(theme => ({
  container: {
    position: "relative",
    background: "white",
    zIndex: 2,
    width: "100%",
  },
  title: {
    textAlign: "center",
    color: "#050124",
    fontFamily: "Reckless TRIAL",
    fontWeight: "700",
    fontSize: "56px",
    lineHeight: "67px",

    [theme.breakpoints.down("sm")]: {
      fontSize: "40px",
      lineHeight: "48px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "27px",
      lineHeight: "41px",
    },
  },

  purple: {
    fontFamily: "Reckless TRIAL",
    color: customTheme.lavender[600],
    wordBreak: "break-word",
  },

  subTitle: {
    color: "#050124",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "130%",
    textAlign: "center",
  },
  curlPath: {
    position: "absolute",
    left: "4px",
    top: "8px",
  },
  leftQuoteMark: {
    position: "absolute",
    height: "fit-content",
    top: "-21px",
    left: "-23px",
  },
  rightQuoteMark: {
    position: "absolute",
    bottom: "-10px",
    height: "fit-content",
    top: "-24px",
    right: "-24px",
  },

  titleDiv: {
    fontFamily: "Reckless TRIAL",
    width: "fit-content",
    position: "relative",
  },
  videoPlayer: {
    maxWidth: "800px",
    borderRadius: 8,
  },
  resultAvailableContainer: {
    background: customTheme.lavender[100],
    borderRadius: 4,
    width: "fit-content",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}))

const HeroSection = ({ countryCode }: { countryCode?: string }) => {
  const classes = useStyles()
  const theme = useTheme<Theme>()
  const isXs = useMediaQuery(theme.breakpoints.down("xs"))
  const images = countryCode === "AE" ? uaeSchools : globalSchools

  return (
    <div className="flex flex-col">
      <div className={classes.container}>
        <div className={clsx("pt-5 md:pt-9 mx-4")}>
          <div className={clsx("mx-auto", classes.title)}>
            <span className={classes.titleDiv}>
              Discover
              <div className={classes.curlPath}>
                <Image alt="curl-path" src={CurlyPath} />
              </div>
            </span>{" "}
            your{" "}
            <div className={clsx("mx-auto", classes.titleDiv, classes.purple)}>
              #TrueTeachingPotential{" "}
              <div className={classes.rightQuoteMark}>
                <Image alt="right-quote-mark" src={RightQuoteMark} />
              </div>
              <div className={classes.leftQuoteMark}>
                <Image alt="left-quote-mark" src={LeftQuoteMark} />
              </div>
            </div>
          </div>
        </div>

        <div
          className={clsx(
            "flex flex-col items-center mt-3 mx-5 md:mb-4 sm:mb-6 mb-4",
            classes.subTitle
          )}
        >
          <span>
            International Teachers’ Olympiad: World’s Largest Multinational
            Olympiad for Teachers
          </span>
        </div>
        <div
          className={clsx(
            "sm:px-4 px-2 py-2 mx-auto md:mb-7 sm:mb-5 mb-6",
            classes.resultAvailableContainer
          )}
        >
          <Typography display="initial" textAlign="center" variant="body">
            <strong>Announcement:</strong> Olympiad Result Reports are Out!
          </Typography>
        </div>
        <div className="flex justify-center px-2 mt-4 mb-5 md:mb-6 sm:mb-7 sm:px-0">
          {/* <RegistrationStatusButton
            className="mx-auto"
            data-source={TRACKING_DATA.source.itoLandingPageHero}
            fullWidth={isXs}
          /> */}
          <Button
            component="a"
            fullWidth={isXs}
            href={getPlatformURL("learn", "/dashboard/ito")}
          >
            Access my Result
          </Button>
        </div>
        <Container>
          <div className={clsx("flex justify-center px-2 sm:px-5 md:px-0")}>
            {/*  eslint-disable-next-line jsx-a11y/media-has-caption */}
            {/* <video
            className={classes.videoPlayer}
            controlsList="nodownload noremoteplayback noplaybackrate"
            poster="https://assets.suraasa.com/ito/video/cover.png"
            src="https://assets.suraasa.com/ito/video/ito.mp4"
            width="100%"
            controls
            disablePictureInPicture
          /> */}
            <Image
              alt="cup-logo"
              src={isXs ? PeopleImageMobile : PeopleImage}
            />
          </div>
        </Container>
      </div>
      <SchoolPartners
        className="pb-0 md:pb-2"
        country={countryCode || "AE"}
        customImages={images}
        customTitle="Teachers from 1800+ Schools across 80+ countries are already onboard!"
        height={100}
      />
    </div>
  )
}

export default HeroSection
